import React, { useState, useEffect } from "react";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { Redirect } from "react-router";
import { connect } from "react-redux";
import { Button, ModalDelete } from "expano-components";
import {
  Main,
  PanelLoader,
  InputField,
  Dashboard,
  AsyncSelectField,
} from "components";
import { getBrand, deleteBrand, updateBrand } from "actions/brands";

import getProducerOptions from "utils/select_options/producer_options";
import BrandChannels from "./components/BrandChannels";

import { ReactComponent as InfoIcon } from "icons/info.svg";
import { ReactComponent as UsersIcon } from "icons/users.svg";

const BrandEdit = ({
  submitting,
  handleSubmit,
  pristine,
  brand,
  change,
  match: {
    params: { id },
  },
  history,
  getBrand,
  updateBrand,
}) => {
  const [is_open_delete_modal, handleOpenDeleteModal] = useState(false);
  useEffect(() => {
    getBrand(id);
  }, [id]);

  const onSubmit = (values) =>
    updateBrand(id, values).catch((err) => {
      throw new SubmissionError(err);
    });
  const handleDelete = () =>
    deleteBrand(id).then(() => history.push("/brands"));

  if (["invalid", "loading"].includes(brand.status)) {
    return <PanelLoader />;
  }

  if (brand.status === "failure") {
    return <Redirect to="/brands" />;
  }

  return (
    <Main
      page={{
        breadcrumbs: [
          { name: "Marki", path: "/brands" },
          {
            name: brand?.data?.name,
          },
        ],
        name: brand?.data?.name,
        icon: <UsersIcon />,
        buttons: (
          <Button
            type="delete"
            text="Usuń markę"
            onClick={() => handleOpenDeleteModal(true)}
          />
        ),
      }}
    >
      {is_open_delete_modal && (
        <ModalDelete
          handleDelete={handleDelete}
          handleClose={() => handleOpenDeleteModal(false)}
          name={`markę ${brand?.data?.name}`}
        />
      )}
      <div className="dashboard-wrapper-flex">
        <div className="column-half">
          <Dashboard
            header={{ icon: <InfoIcon />, title: "Podstawowe dane" }}
            footer={
              <Button
                disabled={pristine || submitting}
                type="save"
                onClick={handleSubmit(onSubmit)}
              />
            }
          >
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
              <div className="dashboard__form__submit">
                <Field label="Nazwa" name="name" component={InputField} />
                <Field
                  name="producer_id"
                  component={InputField}
                  type="hidden"
                />
                <Field
                  name="producer_id"
                  label="Producent"
                  component={AsyncSelectField}
                  change={change}
                  getData={getProducerOptions}
                  initValue={
                    brand?.data?.producer
                      ? {
                          label: brand?.data?.producer?.name,
                          value: brand?.data?.producer?.id,
                        }
                      : null
                  }
                />
              </div>
            </form>
          </Dashboard>
        </div>
        <div className="column-half">
          <BrandChannels />
        </div>
      </div>
    </Main>
  );
};

const mapStateToProps = ({ brand }) => ({
  brand,
  initialValues: {
    name: brand?.data?.name,
    producer_id: brand?.data?.producer?.id,
  },
});
const mapDispatchToProps = (dispatch) => ({
  getBrand: (id) => dispatch(getBrand(id)),
  updateBrand: (id, data) => dispatch(updateBrand(id, data)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: "edit-brand",
    enableReinitialize: true,
  })(BrandEdit)
);
